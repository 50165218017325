import React, {useState, useEffect} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// import {
//     Link
//   } from "react-router-dom";

import ProtocolImage from "../../assets/images/protocol-image.png";
import ProtocolImageMOb from "../../assets/images/element-protocol-mob-image-2.png";

const HomeBannerBanking = () => {

    const[day,setTime4]= useState("");
    const[hour,setTim1]= useState("");
    const[min,setTim2]= useState("");
    const[sec,setTim3]= useState("");
    const[lock,setlock]= useState(""); 
    const[date,setdate]= useState("");
    const[time,settime]= useState("");

    useEffect(async() => {
        await first()
    }, [day, hour, min, sec, lock, date, time]);
    
    const first = async () => {
    
        var us= 1653035400;
        var ff=new Date(us);
    setdate(ff.toDateString());
    var hours = ff.getHours();
      var minutes = ff.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      settime( hours + ':' + minutes + ' ' + ampm);
    //settime(lock);
    var countDowndate   =us * 1000;
    // //console.log(countDowndate);
    // var countDownDate = new Date().getTime() + (lock * 1000) ;
    //alert(time);
        var x = setInterval(function() {
           var now = new Date().getTime();
          var distance = countDowndate - now ;
        //    //console.log("-------------------now", distance);
         //  //console.log(now);
          // Time calculations for days, hours, minutes and seconds
         var days = Math.floor(distance / (1000 * 60 * 60 * 24));
          var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            
        //    //console.log("date e", day);
        //    //console.log("hour e", hour);
        //    //console.log("min e", minutes);
        //    //console.log("sec e", seconds);
    
          // Output the result in an element with id="demo"
         // document.getElementById("demo").innerHTML = hours + "h "
         // + minutes + "m " + seconds + "s ";
        setTime4(days);
        setTim1(hours);
        setTim2(minutes);
        setTim3(seconds);
    
    
        
        
        
        
          // If the count down is over, write some text 
          if (distance < 0) {
                clearInterval(x);
                setlock(false);
    
               //  //console.log('CountDown Finished');
            }
            else{
             setlock(true);
            }
    
        
          
        }, 1000);
       
    
    }

    return (
        <div className='page-banner text-center'>
            <Container fluid="lg">
                <Row className='justify-content-center py-0'>
                    <Col xl={12}><br/>
                        <h2>ELEMENT</h2>
                    </Col>
                </Row>

                <div className="text-center banner-protocol-image">

                    <img src={ProtocolImage} alt="ProtocolImage" className='img-fluid d-none d-md-block mx-auto' />
                    <img src={ProtocolImageMOb} alt="ProtocolImage" className='img-fluid d-md-none w-100' />
                    
                </div>

                <Row className='justify-content-center banner-protocol-text'>
                    <Col xl={12}>
                       <p>Next-Gen Multi-dimensional Stablecoin and DeFi 2.0 Platform on Algorand </p>
                       <h4>ELEMENT T2 TestNet</h4>
                       <h4>Thank you for your interest in ELEMENT. We appreciate each one of you, who have tested our platform, and given valuable feedback. We have officially wrapped up round 2 testing.</h4>
                       {/* <h3 className='mb-0'>{lock === true ? (<>{day}d : {hour}h : {min}m : {sec}s</>):(<>{0}d : {0}h : {0}m : {0}s</>)}</h3> */}
                        {/* <p>TAU is the first non-dilutive fractional stablecoin for DeFi 2.0, where the price balance is orthogonally regulated through ELEM elastic supply adjustments, burn and bonding mechanics within a closed ecosystem.</p> */}
                    </Col>
                </Row>

                {/* <div className="pt-4">
                <Link to="/home" className='m-md-2 mb-3 btn btn-lg btn-sm-full d-md-none btn-grad'>Stablecoin Hub</Link>
                <Link to="/home" className='m-md-2 mb-3 btn btn-lg btn-sm-full d-md-none btn-grad'>All-In-One DeFi</Link>
                <Link to="/home" className='m-md-2 mb-3 btn btn-lg btn-sm-full d-md-none btn-grad'>SaaS (Private L2)</Link> */}
                {/* <Link to="/swap" className='m-md-2 mb-3 btn btn-lg btn-sm-full d-md-none btn-grad'>AMM DEX</Link>
                <Link to="/lending" className='m-md-2 mb-3 btn btn-lg btn-sm-full d-md-none btn-grad'>Money Market</Link>
                <Link to="/launchpad" className='m-md-2 mb-3 btn btn-lg btn-sm-full d-md-none btn-grad'>Launchpad</Link>
                <Link to="/farm" className='m-md-2 mb-3 btn btn-lg btn-sm-full d-md-none btn-grad'>Yield Farming</Link> */}
                {/* <a target="_blank" rel="noreferrer" className='m-md-2 mb-3 btn btn-lg btn-sm-full d-md-none btn-grad'>NFT Market</a> */}
                    {/* <Link to="/home" className='m-md-2 mb-3 btn btn-lg btn-mob-full d-none d-md-inline-block btn-grad'>Stablecoin Hub</Link>
                    <Link to="/home" className='m-md-2 mb-3 btn btn-lg btn-mob-full d-none d-md-inline-block btn-grad'>All-In-One DeFi</Link>
                    <Link to="/home" className='m-md-2 mb-3 btn btn-lg btn-mob-full d-none d-md-inline-block btn-grad'>SaaS (Private L2)</Link> */}
                    {/* <Link to="/swap" className='m-md-2 mb-3 btn btn-lg btn-mob-full d-none d-md-inline-block btn-grad'>AMM DEX</Link>
                    <Link to="/lending" className='m-md-2 mb-3 btn btn-lg btn-mob-full d-none d-md-inline-block btn-grad'>Money Market</Link>
                    <Link to="/launchpad" className='m-md-2 mb-3 btn btn-lg btn-mob-full d-none d-md-inline-block btn-grad'>Launchpad</Link>
                    <Link to="/farm" className='m-md-2 mb-3 btn btn-lg btn-mob-full d-none d-md-inline-block btn-grad'>Yield Farming</Link>
                    <a target="_blank" rel="noreferrer" className='m-md-2 mb-3 btn btn-lg btn-mob-full d-none d-md-inline-block btn-grad'>NFT Market</a> */}
                {/* </div> */}
            </Container>
        </div>
    );
};

export default HomeBannerBanking;